@font-face {
  font-family:"proxima-nova";
  src:url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
  }

  @font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
    } 


body {
  margin: 0;
  font-family: "proxima-nova" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-x: hidden !important;
}

.btn.btn-primary.report-button{
  width: 250px;
  height: 36px;
  background-color: transparent;
  border-color: #D0D5DD;
  color: #224178;
  font-size: 14px;
  
}

.report-svg{
  margin-right: 5px !important;
}

.report-section{
  display: flex;
  justify-content: center;
  
  position: fixed;
  bottom: 0px;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 30px;
  width: 348px;
}

.btn.btn-primary.report-button:hover {
  background-color: #224178;
  border-color: #224178;
  color: #fff;
  font-size: 14px;
}

.password-form-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.padding-5{
padding-right: 5px !important;
padding-left:5px !important;
}

.container{
  max-width:100% !important
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-center div.container{
  justify-content: center;
}

.results-right-col{
  height: calc(100vh - 136.75px); 
  overflow: scroll;
  max-width: 700px !important;
  overflow-x: hidden;
}

.results-left-col.col {
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 10px;
  background-image: url("/src/components/images/Ocean-blue.svg");
}

.listing-grid {
  display: grid;
  grid-template-columns: calc(50% - 15px) calc(50% - 15px);
  gap: 10px;


}
.listing-grid-3 {
  display: grid;
  grid-template-columns: calc(33% - 5px) calc(33% - 5px) calc(33% - 5px);
  gap: 10px;


}

.listing-count {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1669B4;
  margin-top: 20px;
  margin-bottom: 20px;
}

.listing-card.card{
  border-radius: 4px;
  width:100%;
  border:none;
  filter: drop-shadow(0px 0px 10px #D8D8D8);
 

}

.listing-image.card-img-top{
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  aspect-ratio: 2/1;
  background-color: #fff;
  background-position: center center;
  object-fit: cover;
}

.listing-body.card-body{
  padding:10px 10px 10px 10px;
}

.listing-body.card-body .col {
  padding: 0px;
}

.listing-price.card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #1669B4;
  margin-bottom: 0px;
}

.listing-details {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #667085;
}

.listing-availability {
  font-weight: 400;
  font-size: 12px;
  line-height: 32px;
  text-align: right;
  color: #667085;
}



.empty-state-bg {
  background-color: #F4F8FC;
  height: calc(100vh - 159px);
  background-image: url('/src/components/images/Purelogo.svg');
  background-position: center center;
  background-repeat: no-repeat;
}

h3.empty-state-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: #1669B4;
  padding: 13px 20px;
}

h3.no-result {
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: #1669B4;
  padding: 0px;
  margin-top: 30px;
}

.search-module {
  background-color: #ffffff;

}



.form-control.search-bar {
  padding: 12px 20px;
  border-radius: 8px;
}

.btn.dropdown_btn {
  background-color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #224178;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 12px 20px;
}

.btn.dropdown_btn:hover,
.btn.dropdown_btn:active {
  background-color: #1669B4 !important;
  border-color: #1669B4 !important;
}

.modal-backdrop {
  --bs-backdrop-bg: #1669B4 !important;
  --bs-backdrop-opacity: 0.9 !important;
  opacity: 0 !important;
}

.listing-modal-main{
background-color: rgba(22, 107, 182, 0.9);
}

.modal-dialog.listing-modal {
  max-width: 957px;
  margin-top: 0px;
  margin-bottom: 0px;
}


.modal-body-full {
  padding: 0px
}

.modal-body-content {
  padding: 0px;
}

.modal-dialog.listing-modal .modal-header {
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 19px;
}

.modal-left {
  padding-top: 17px;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.modal-right{
  padding-top: 19px;
  padding-left: 14px !important;
  padding-right: 14px !important;
  padding-bottom:19px;
}

/* .modal-dialog.listing-modal .btn-close {
  position: absolute;
  right: -40px;
  background: #fff url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e) center/1em auto no-repeat;
  border-radius: 100%;

} */

.modal-icons.col {
  display: flex;
  justify-content: flex-start;
}

.error-report{
  
  height: 170px;
}

.listing-modal-new{
  width: 280px !important;
  
}

.send-button{
  margin-left: 104px !important;
  width: 73px;
  height: 36px;
  font-weight: 700 !important;
  font-size: 14px !important;
  background: linear-gradient(0deg, #0D61A6, #0D61A6),
  linear-gradient(0deg, #0D63A6, #0D63A6);
  border: 1px solid rgba(13, 97, 166, 1)
  ;
}



.error-report-logo{
  margin: 5px;
}



.text-box{
  width: 218px !important;
  height: 89px !important;
  margin-left: 28px;
  border-radius: 8px !important;

}

.mr-20px {
  margin-right: 20px
}

h3.modal-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0B2043;
  text-align: left;
  margin-top: 17px;
}

h3.modal-title .price {
  color: #1669B4;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

h4.modal-subtitle {
  font-weight: 400;
  font-size: 12px;
  color: #667085;
  margin-top: 15px;
}

p.modal-paragraph {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  Color: #0B2043;
  white-space: pre-wrap;
}

.modal-paragraph.paragraph-title{
  margin-top: 16px;
}

.modal-paragraph-sm {
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  Color: #0B2043;
  margin-bottom: 0px;
}

.price-dropdown.dropdown-menu {
  width: 300px;
}

.price-dropdown .dropdown-item:before {
  content: "$";
}

.price-dropdown .max-value-dropdown .dropdown-item:last-child::before {
  content: "";
}



.price-dropdown .dropdown-item .mark,
.price-dropdown .dropdown-item mark {
  padding: 0px;
  background-color: #D3E1F0;
}

.distance-dropdown.dropdown-menu {
  width: 150px;
}

.distance-dropdown .dropdown-item::after {
  content: " Miles";
}

.distance-dropdown .dropdown-item .mark,
.distance-dropdown .dropdown-item mark {
  padding: 0px;
  background-color: #D3E1F0;
}

.dropdown-title.form-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #224178;
  padding-bottom: 15px;
  display: inline-block;
}

p.dropdown-description {
  font-size: 14px;
}

.INdropdown-button.btn {
  background-color: #1669B4;
  border-color: #1669B4;
  margin-bottom: 10px;
  -webkit-appearance: none;
  font-size: 14px;

}

.INdropdown-button.btn:hover {
  background-color: #224178;
  border-color: #224178;
}

.schedule-button.btn {
  background-color: transparent;
  border-color: #D0D5DD;
  color: #224178;
  font-size: 14px;
}

.schedule-button.btn:hover {
  background-color: #224178;
  border-color: #224178;
  color: #fff;
  font-size: 14px;
}

.INdropdown-button-close.btn {
  background-color: transparent;
  border-color: #1669B4;
  margin-bottom: 10px;
  -webkit-appearance: none;
  color: #1669B4;
  margin-left: 20px;
  font-size: 14px;
}

.INdropdown-button-close.btn:hover {
  background-color: #224178;
  border-color: #224178;
}

.bb-dropdown.dropdown-menu {
  width: 350px;
}

.dogscats-dropdown.dropdown-menu {
  width: 205px
}

.dropdown-menu.date-dropdown {
  width: 230px
}

.date-picker {
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  padding: 10px 5px;
  font-size: 14px;

}



.left-padding {
  padding-left: 0px !important;
}

.fill-white {
  fill: #fff
}

h3.listing-number {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #1669B4;
  margin-top: 20px;
  margin-bottom: 0px;
}

h4.result-number {
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: #224178;
  margin-bottom: 25px;
  margin-top: 0px;
}

.property-details {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  border-bottom: 1px solid #E5E5E5;
}


.padding-0 {
  padding: 0px !important
}

.preview-column-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


h5.listing-preview-price {
  font-family: "proxima-nova" !important;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #1669B4;
  text-align: left;
}

h6.listing-preview-details {
  font-family: "proxima-nova" !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #667085;
  text-align: left;
  text-transform: none;
}

.catsdogs-toggles {
  display: flex;
  flex-direction: column;

}

.catsdogs-toggles .switch {
  width: 100%
}

.switch-off.btn {
  padding-left: 12px !important
}

.toggle {
  margin-bottom: 16px;
}

.button-column {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
}


.apply-button {
  margin-right: 7px;
  margin-bottom: 0px !important;
  height: 36px !important;
  width: 77px !important;
}

.schedule-button {
  margin-right: 7px;
  margin-bottom: 0px !important;
  height: 36px !important;
  width: 154px !important;
}

.carousel .w-100 {
  width: auto !important;
  height: 400px !important;
  background-color: rgba(22, 107, 182, 0.15) !important;
}

.carousel .carousel-item {
  display: flex !important;
  justify-content: center !important;
  background-color: #DFE9F4;

}

@media only screen and (max-width: 1279px){
  .listing-grid{
    grid-template-columns: 100%;
  }

  .results-left-col{
    flex-grow: 2 !important;
  }

 



}

@media only screen and (min-width: 1006px){
  .mobile{
    display: none !important;
  
  }
  
  .search-bar-mobile{
    display: none !important;
  }
  
  
  
  
  }

@media only screen and (max-width: 1006px) and (min-width: 906px){
  .listing-grid{
    /* grid-template-columns: 33% 33% 33%; */
    grid-template-columns: calc(33% - 3.33px) calc(33% - 3.33px) calc(33% - 3.33px);
  }

  .search-bar-mobile{
    display: none;
  }

  .no-photo{
    height: 30vh !important;
    padding: 0;
    margin: 0;
  }

  .no-photo-txt{
    margin: 100px !important;
  }
}



@media only screen and (max-width: 905px) and (min-width: 600px){
  .listing-grid{
    /* grid-template-columns: 50% 50%; */
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  }

  .no-photo{
    height: 30vh !important;
    padding: 0;
    margin: 0;
  }

  .no-photo-txt{
    margin: 100px !important;
  }
}



/* Mobile Styles */

@media only screen and (max-width: 1006px) and (min-width: 576px){
  .mobile-results-tabs {
    margin-top: 20px !important;
   
  }
}

@media only screen and (max-width: 1006px){

  .google-map-mobile {
    height: 100vh;

  }

  .grid-example .row{
    display: grid;
    grid-template-columns: 50% 50%;

  }

  .search-module.container {
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
  }


  .tab-content:has(> .google-map-mobile.active) {
    padding: 0px !important;
  }

  .hamburger-menu.col {
    /* margin: 0 !important; */
    /* padding: 0 !important; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .nav-tabs .active {
    border: 1px solid #D0D5DD !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }

  .desktop{
    display: none !important;
  }

  #main-divhedar{
    display: none !important;
  }

  .mobile-results-tabs {
   
    justify-content: center;
    background-color: rgba(244, 248, 251, 1) !important;
    padding-top: 20px;
    padding-bottom: 20px;
    position: fixed;
    z-index: 50;
    bottom: 0;
  }

  .mobile-results-tabs .nav-item button {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #224178 !important;
    
  }

  .mobile-results-tabs .nav-item:first-child button:before {
    content: "";
    display: block;
    background: url("/src/components/images/list.svg") no-repeat;
    width: 20px;
    height: 26px;
    float: left;
    margin: 0 6px 0 0;
    background-position: center;
  }

  .mobile-results-tabs .nav-item:nth-child(2) button:before {
    content: "";
    display: block;
    background: url("/src/components/images/map.svg") no-repeat;
    width: 20px;
    height: 26px;
    float: left;
    margin: 0 6px 0 0;
    background-position: center;
  }
}

/* Mobile Styles */

@media only screen and (max-width: 600px){
  .no-photo{
    height: 25vh !important;
    padding: 0;
    margin: 0;
  }

  .social-media-share{
    margin-left: 35% !important ;
    margin-right: auto !important;

  }

  .no-photo-txt{
    margin: 100px !important;
  }
}

@media only screen and (max-width: 576px) {

  .desktop {
    display: none !important;
  }
  .mobile-results-tabs {
    margin-top: 10px !important;
   
  }

  .no-photo{
    height: 25vh !important;
    padding: 0;
    margin: 0;
  }

  .no-photo-txt{
    margin: 100px !important;
  }

  

  

  .mobile-logo.col {
    display: flex;
    align-items: center;
    margin-right: -15px !important;
  }


  /* .hamburger-menu.col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  } */

  .rbt {
    height: 100% !important;
    background-color: red;
  }

  
  .empty-state-bg {
    background-size: contain;
  }

  .modal-dialog.listing-modal .btn-close {
    right: 30px
  }

  .google-map-mobile {
    height: 100vh;

  }



  .tab-content:has(> .google-map-mobile.active) {
    padding: 0px !important;
  }

  

  /* .mobile-results-tabs {
    justify-content: center;
    background-color: rgba(28, 110, 183, 0.05);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .mobile-results-tabs .nav-item button {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #224178 !important;
  }

  .mobile-results-tabs .nav-item:first-child button:before {
    content: "";
    display: block;
    background: url("/src/components/images/list.svg") no-repeat;
    width: 20px;
    height: 26px;
    float: left;
    margin: 0 6px 0 0;
    background-position: center;
  }

  .mobile-results-tabs .nav-item:nth-child(2) button:before {
    content: "";
    display: block;
    background: url("/src/components/images/map.svg") no-repeat;
    width: 20px;
    height: 26px;
    float: left;
    margin: 0 6px 0 0;
    background-position: center;
  } */

  .nav-tabs {
    --bs-nav-tabs-border-width: 0px !important;
  }

  .nav-tabs .active {
    border: 1px solid #D0D5DD !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }

  .listing-body.card-body .row {
    flex-direction: column;
  }

  .listing-availability.card-text {
    text-align: left;
  }

  .modal-body-content .row {
    flex-direction: column;
  }






}



input[type="date"]::-webkit-datetime-edit,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  color: #fff;
  position: relative;
}

input[type="date"]::-webkit-datetime-edit-year-field {
  position: absolute !important;
  border-left: 1px solid #8c8c8c;
  padding: 2px;
  color: #000;
  left: 58px;
}

input[type="date"]::-webkit-datetime-edit-day-field {
  position: absolute !important;
  border-left: 1px solid #8c8c8c;
  padding: 2px;
  color: #000;
  left: 32px;
  min-width: 32px;
}





input[type="date"]::-webkit-datetime-edit-month-field {
  position: absolute !important;
  color: #000;
  padding: 2px;
  left: 0px;
  min-width: 32px;

}

.share{
  margin-left: 5px;
  margin-bottom: 5px;
}

.share-button.btn {
  background-color: transparent;
  border-color: #D0D5DD;
  color: #224178;
  font-size: 14px;
 
}

.share-button.btn:hover {
  background-color: #224178;
  border-color: #224178;
  color: #fff;
  font-size: 14px;
}

.share-button {
  margin-bottom: 0px !important;
  height: 36px !important;
  width: 101px !important;
}

.rboundry.btn {
  background-color: white;
  border-color: #D0D5DD;
  color: #224178;
  font-size: 14px;
  font-weight: 700;
 
}
.rboundry-button.btn:hover {
  background-color: #224178;
  border-color: #224178;
  color: #fff;
  font-size: 14px;
}

.rboundry-button {
  margin-bottom: 0px !important;
  height: 100px !important;
  width: 101px !important;
}

.report-section{
  display: flex;
  justify-content: center;
}


.btn.btn-primary.report-button{
  width: 250px;
  height: 36px;
  background-color: transparent;
  border-color: #D0D5DD;
  color: #224178;
  font-size: 14px;
  
}

.btn.btn-primary.report-button:hover {
  background-color: #224178;
  border-color: #224178;
  color: #fff;
  font-size: 14px;
}

.report-svg{
  margin-right: 5px !important;
}


.error-report-head{
  /* padding: 10px;
  
  background-color: transparent;
  border-color: #D0D5DD;
  color: #224178;
  font-size: 14px; */
  padding-left: 70px;
  font-size: 14px !important;
  font-weight: 700 !important;
  text-align: center !important;
 
  
}

.share-the-property{
  font-size: 14px !important;
  font-weight: 700 !important;
  text-align: center !important;
}

.error-report-logo{
  margin: 5px;
  padding-right: 2px;
}





.error-report{
  
  height: 170px;
}

.send-button{
  margin-left: 104px !important;
  width: 73px;
  height: 36px;
  font-weight: 700 !important;
  font-size: 14px !important;
  background: linear-gradient(0deg, #0D61A6, #0D61A6),
  linear-gradient(0deg, #0D63A6, #0D63A6);
  border: 1px solid rgba(13, 97, 166, 1)
  ;
}

.error-report-p{
  line-height:20px !important;
  font-size: 14px;
  text-align: center;
  color: rgba(31, 54, 103, 1);

}

.address{
  font-weight: 700 !important;
}

.manager{
  font-weight: 400 !important;
}

.social-media-share{
  width: 270px !important;
  margin-left: 350px;


}


.social-media-item{
  display: flex !important;
  justify-content:space-around !important;
}



.search-module.container .desktop .col{
  padding-left: 5px ;
  padding-right: 5px ;
}

.dropdown-toggle::after{
  border: solid #00A4E6 !important;
  border-width: 0 2px 2px 0 !important;
  margin-left: 10px !important;
  display: inline-block !important;
  padding: 3px !important;
  transform: rotate(45deg) !important;
  -webkit-transform: rotate(45deg)!important;
}

.desktop-filterbar{
  background-color: rgba(28, 110, 183, 0.05);
  padding: 12px;
}

.home-types div{
 margin-bottom: 5px;
}

.home-types div input{
  margin-right: 5px;
}

.mobile-logo{
  display: flex;
    align-items: center;
}



.mobile-results-tabs{
  margin-top: 0px !important;
}

.mobile-results-tabs.nav{
  padding: 12px !important;
}

.form-check-label{
  font-size: 14px;
}

.home-types div{
  font-size: 14px;
}

.switch-group span{
  font-size: 14px;
}

.dropdown-item{
  font-size: 14px;
}


.form-control{
  font-size: 14px !important;
}




.home-type-mobile{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  margin-bottom: 16px !important;
}

@media only screen and (min-width: 1078px){
  
  
  .modal-dialog.listing-modal .btn-close {
    position: absolute;
    right: -40px;
    background: #fff url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e) center/1em auto no-repeat;
    border-radius: 100%;
  
  }
  
  
  
  }


  .listing-modal.main-card .modal-content{
        border-radius: 0px;
        min-height: 100vh;
  }

  /* .modal-dialog .listing-modal .main-card .modal-content{

    height: 100vh;
  } */


  /* The container must be positioned relative: */
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}


/* location dialog box styles */

.modal-full-width {
  max-width: 100%;
  margin: 0;
}

.modal-full-width .modal-dialog {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.modal-full-width .modal-content {
  border: none;
  border-radius: 0;
}

/* style state and cities in america */

.grid-example .state-in-america{
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #0D5FA6 !important;
  font-weight:bold;
  letter-spacing: 1px;
  text-transform:uppercase !important;
  line-height: 18px;
  
 
  
}



.city-link{
  list-style-type: none;
  font-size: 13px !important;
  margin: 0;
  padding: 0;
  padding-left: 10px;
  line-height: 14px;
  text-decoration: none;
  color: #0A1F44;
  font-weight: 500;
  
}


.state-province{
  padding: 0;
  margin: 0;
  font-size: 10px;
  letter-spacing: 1px;
  font-weight:400;
  color: #333333;
  line-height: 20px;
}

  .grid-example .city-col{
  grid-column-gap: 16px;
    grid-row-gap: 10px;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display:flex !important;

}

.location-title{
  padding: 0;
  margin: 0;
  color: midnightblue;
  /* margin-bottom: 8px;
  padding-top: 12px;
  padding-bottom: 12px; */
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
}

.logo-desk{
  cursor: pointer;
}



.referencelinks-dropdown > div{
  margin-top: 63px !important;
}


.referencelinks-dropdown .modal-dialog{
  box-shadow:14px 14px 40px 5px rgba(118,126,173,.1)
}

.gmnoprint.gm-bundled-control .gmnoprint div{ 
  border-radius: 8px !important;
}

.gm-control-active{
  display: none;
}

/* .location-button.btn {
  background-color: #224178;
  border-color: #D0D5DD;
  color: #D0D5DD;
  font-size: 14px;
 
} */



.location-button.btn:hover {
  background-color: #224178;
  border-color: #224178;
  color: #fff;
  font-size: 14px;
}


 .location-button.btn {
  background-color: #1669b4;
  border-color: #1669b4;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}


 .search-bar-mobile{
  padding: 10px 10px 0 0;
  display: flex;
  justify-content: center !important;
  /* margin-left: -30px; */
  width: 100vw;
 
}


.location-col{
  text-align: center;
}

.toolTipImage{
  aspect-ratio: 1/1;
    background-color: #fff;
    background-position: 50%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    object-fit: cover;

}


.render-list{
  display: grid;
  grid-template-columns: 100%;
  row-gap: 5px;
}

.render-list .col{
  padding: 0;

}

.render-list .row{
  gap: 10px;

}

.loadingSkeleton{
  width: 100%;
  height: 253px;
}

.loadingListSkeleton{
    max-width: 150px;
    height: 24px;
    margin: 20px 0 20px 0;
}

.no-photo{
  width: 100%;
  background-color: #F4F8FC;
  height: 50vh;
}

/* .no-photo-txt{
  color: #0D63A6;
text-align: center;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 24px;
} */

.no-photo-txt {
  color: #0D63A6;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 170px;
}